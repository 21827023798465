
import BackgroundVideoPlayer from "@/components/BackgroundVideoPlayer"
import { EvercamApi } from "@evercam/shared/api/evercamApi"

export default {
  components: {
    BackgroundVideoPlayer,
  },
  layout: "clean",
  meta: {
    public: true,
  },
  async asyncData({ query, redirect, $notifications }) {
    const key = query.unsubscribeKey

    try {
      if (key) {
        await EvercamApi.mailing.unsubscribe({ key })
      } else {
        throw new Error("Key parameter missing")
      }
    } catch (error) {
      console.log(error)
      $notifications.error({
        text: "Error unsubscribing",
        error,
      })
      redirect("/v2/users/signin")
    }
  },
}
